import styles from "./friendInviteOneInvite.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores/index";
import LoadedComponent from "widgets/LoadedComponent";

import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { TextFieldGroup } from "shared/ui/TextFieldGroup";
import {
  FieldsGroups,
  groups
} from "stores/FriendInviteModule/friendInviteOne/friendInviteOne";
import { getKeys } from "shared/utils/helpers/getKeys";
import FriendInviteOneSelect from "./FriendInviteOneSelect";
import FriendInviteOneStaff from "./FriendInviteOneStaff";
import { calculateYears } from "shared/utils/helpers/calculateYears";
import { getParsedDate } from "shared/utils/helpers/getParsedDate";
import { getUnits } from "shared/utils/helpers/getUnits";

const FriendInviteOneInvite = () => {
  const { menuStore, friendInviteOneStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    menuStore.setOpenedModule("friendInviteOne");
    menuStore.setOpenedSubmodule("friendInviteOne");
  }, []);

  useEffect(() => {
    menuStore.sendTabId(id);
    friendInviteOneStore.setSelectedOneInvite(id);
    menuStore.updateWindow({
      mainPath: `/friendinvite/id=${id}`,
      path: `/friendinvite/id=${id}`
    });
  }, [id]);

  const getValue = (name: string) => {
    const value = friendInviteOneStore.selectedOne[name]?.title
      ? friendInviteOneStore.selectedOne[name]?.title
      : getFormattedDate(friendInviteOneStore.selectedOne[name]);

    if (name !== "status" && friendInviteOneStore.selects[name]?.[value]) {
      return friendInviteOneStore.selects[name][value]?.title || value;
    }

    if (["timestamp"].includes(friendInviteOneStore.cols[name].type)) {
      return value?.replace(/ /g, ", в ");
    }

    switch (name) {
      case "status":
      case "pay":
        return <FriendInviteOneSelect name={name} value={value} />;
      case "phone_1":
      case "phone_2":
        return formatPhoneNumber(friendInviteOneStore.selectedOne[name] || "");
      case "uid":
      case "candidate_tn":
        return <FriendInviteOneStaff name={name} value={value} />;
      case "candidate_birthday":
        return (
          <div className={styles.birthday}>
            {getFormattedDate(value)}
            <span className={styles.age}>{`(${calculateYears(
              getParsedDate(getFormattedDate(value)),
              new Date()
            )} ${getUnits(
              calculateYears(
                getParsedDate(getFormattedDate(value)),
                new Date()
              ),
              "год",
              "года",
              "лет"
            )})`}</span>
          </div>
        );
      default:
        return getFormattedDate(value || "");
    }
  };

  return (
    <LoadedComponent
      isLoading={friendInviteOneStore.isLoading}
      error={friendInviteOneStore.error}
    >
      <div className={styles.container}>
        <div className={styles.title}>Описание</div>
        {getKeys(friendInviteOneStore.cols)
          .filter((title) => friendInviteOneStore.cols[title]?.hidden !== "on")
          .map((title) => {
            let groups_name: FieldsGroups[number];
            getKeys(groups).map(
              (name) => groups[name].includes(title) && (groups_name = name)
            );

            // Отработано смен по дизайну находится в поле candidate_tn, поэтому тут его не отображаем
            if (title === "candidate_days") return;

            if (groups_name) {
              return (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <TextFieldGroup
                    name={title}
                    fields={groups[groups_name]}
                    titles={getKeys(friendInviteOneStore.cols)}
                    cols={friendInviteOneStore.cols}
                    getValue={getValue}
                  />
                </ErrorBoundary>
              );
            }
            switch (title) {
              default:
                return (
                  <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                    <TextFieldGroup
                      name={title}
                      fields={[title]}
                      titles={getKeys(friendInviteOneStore.cols)}
                      cols={friendInviteOneStore.cols}
                      getValue={getValue}
                    />
                  </ErrorBoundary>
                );
            }
          })}
      </div>
    </LoadedComponent>
  );
};

export default observer(FriendInviteOneInvite);
