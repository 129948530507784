import styles from "./friendInviteOneSelect.module.scss";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { useStores } from "stores/index";
import { Formik } from "formik";

type FriendInviteOneSelectProps = {
  name: string;
  value: string | number;
};

const FriendInviteOneSelect = ({ name, value }: FriendInviteOneSelectProps) => {
  const {  friendInviteOneStore } = useStores();

  const referenseRef = useRef<HTMLTableDataCellElement>();

  return (
    <Formik initialValues={{ value }} onSubmit={() => null} data-list="true">
      {({ values }) => {
        return (
          <>
            <div ref={referenseRef} className={styles.field} data-list="true">
              <div>
                {friendInviteOneStore.selects[name]?.[values.value]?.title ||
                  values.value ||
                  ""}
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default observer(FriendInviteOneSelect);
