import styles from "./friendInviteSelects.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Formik } from "formik";

type FriendInviteSelectsProps = {
  id: string;
  title: string;
};
const FriendInviteSelects = ({ id, title }: FriendInviteSelectsProps) => {
  const { friendInviteStore } = useStores();

  return (
    <td className={styles.td} data-type="list">
      <Formik
        initialValues={{
          value: friendInviteStore.invitesList[id]?.[title] || ""
        }}
        onSubmit={() => null}
      >
        {({ values }) => (
          <>
            <div data-type="list" className={styles.field}>
              <div data-type="list">
                {friendInviteStore.selects[title]?.[
                  values.value || friendInviteStore.invitesList[id][title]
                ]?.title || friendInviteStore.invitesList[id]?.[title]}
              </div>
            </div>
          </>
        )}
      </Formik>
    </td>
  );
};

export default observer(FriendInviteSelects);
