import styles from "./friendInviteOneInvite.module.scss";
import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";
import { useStores } from "stores/index";
import { InvitesListItem } from "stores/FriendInviteModule/types/InvitesListItem";
import { getValues } from "shared/utils/helpers/getValues";
import { Badge } from "shared/ui/Badge";
import { fileUrl } from "stores/utils/consts";

type FriendInviteOneStaffProps = {
  name: string;
  value: InvitesListItem["uid"] | InvitesListItem["candidate_tn"];
};

const FriendInviteOneStaff = ({ name, value }: FriendInviteOneStaffProps) => {
  const { friendInviteOneStore } = useStores();

  const { id } = useParams();

  return (
    <div className={styles.staff} data-type="list">
      <div className={styles.staffInfo} data-type="list">
        {value?.id ? (
          <Link
            to={`/staff/id=${value.id}`}
            id={`FriendInviteStaffForm_${name}_${value.id}`}
            className={styles.link}
            data-type="list"
            target="_blank"
          >{`ТН ${value.tn || ""}${" " + value.name || ""}`}</Link>
        ) : (
          <span className={styles.emptyTN}>
            Выберите ТН из списка (в разработке)
          </span>
        )}

        {value &&
          name === "candidate_tn" &&
          friendInviteOneStore.cols?.candidate_days && (
            <div className={styles.workshift}>
              <span className={styles.workshiftTitle}>
                {friendInviteOneStore.cols?.candidate_days?.title}:
              </span>
              <span className={styles.workshiftCount}>
                {friendInviteOneStore.selectedOne?.candidate_days || 0}
              </span>
            </div>
          )}
      </div>

      {value?.events &&
        getValues(value.events).map((event, idx) => {
          return (
            <div
              className={styles.event}
              key={`event_${id}_${idx}`}
              data-type="list"
            >
              <div className={styles.company} data-type="list">
                <Badge
                  text={
                    friendInviteOneStore.companies?.[event.company]?.title ||
                    event.company
                  }
                  colorName={
                    friendInviteOneStore.companies?.[event.company]?.color ||
                    "primary"
                  }
                />
                <Badge
                  text={event.type.title}
                  textColorName="bw-gray7"
                  icon={event.type.icon}
                  iconColor={event.type.color}
                  colorName="none"
                />

                <a
                  href={`${fileUrl}/crm/building/?id=${event.building?.id}`}
                  id={`FriendInviteStaffForm_${name}_${event.building?.id}`}
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                  data-type="list"
                >
                  {event.building?.title}
                </a>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default observer(FriendInviteOneStaff);
