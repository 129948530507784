import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Selects } from "stores/StaffModule/types/Selects";
import { Col } from "stores/StaffModule/types/Col";
import { History } from "stores/StaffModule/types/History";
import {
  ICompany,
  IRecordsForEdit
} from "stores/StaffModule/types/RecordsForEdit";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { TableParams } from "../types/TableParams";
import { parse } from "date-fns";
import { cloneDeep, isEmpty, startCase, without } from "lodash";
import { Status } from "../types/Status";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";
import { StaffCardProps } from "../types/StaffCardProps";

export type LoadingTypes = "phone" | "email" | "settings" | "photo";
interface IColForEdit extends Col {
  disabled?: string;
}

interface IErrorStaffEdit extends Errors {
  from?: string;
}

type AllDataForEdit = {
  action: string;
  columns: Record<string, IColForEdit>;
  company: Record<string, ICompany>;
  company_fields: Record<string, string>;
  groups: StaffGroups;
  record: IRecordsForEdit;
  selects: Selects;
  ov_cols: Record<string, { withHistory: number }>;
  table_params: Record<string, TableParams>;
  is_tech_access: boolean;
  errors?: string;
  message?: Errors["message"];
  blocked_cols?: Record<string, string>;
};

type StaffGroups = Record<
  string,
  {
    columns?: { [key: string]: string };
    dadata?: unknown;
    icon?: string;
    id?: string;
    sort?: number;
    title: string;
  }
>;

type HistoryData = Record<string, History>;
export default class staffOneEditStore {
  error = false;
  errorText: Record<string, string> = {};
  errorsMessage: Record<string, Partial<IErrorStaffEdit>> = {};
  isHistoryError = false;
  isLoading = false;
  isLoadingPhoto = false;
  isLoadingPhotoAsMain = false;
  isLoadingForForm = false;
  isLoadingSettings = false;
  isLoadingPhone = false;
  isLoadingEmail = false;
  isLoadingField = "";
  success = false;
  successDelete = false;
  isTechAccess = false;

  historyData: HistoryData = {};
  building_staff_events: Record<
    string,
    Record<string, Record<string, Record<string, Status>>>
  > = {};

  columns: Record<string, IColForEdit> = {};
  tableParams: Record<string, TableParams> = {};
  groups: StaffGroups = {};
  selects: Partial<Selects> = {};

  selectedOneForEdit: Partial<IRecordsForEdit> = {};
  openedAllStaffForEdit: Record<string, Partial<IRecordsForEdit>> = {};

  allCompanies: Record<string, ICompany> = {};
  allCompaniesList: Record<string, { id: string; title: string }> = {};

  addedCompanies: Record<string, string[]> = {};
  selectedCompanyId: Record<string, string> = {};
  selectedTypeOfJob: Record<string, string> = {};
  lastStatus = "";

  objectVersionColumns: AllDataForEdit["ov_cols"] = {};
  rootStore: RootStore;
  blockedCols: Record<string, string[]> = {};
  openedOVModal: Record<string, string> = {};

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this, {});
  }

  setSuccess(value: boolean) {
    this.success = value;
  }

  setSuccessDelete(value: boolean) {
    this.successDelete = value;
  }

  setSelectedCompanyId = (
    uid: string,
    id: string,
    withEmployment?: boolean
  ) => {
    this.selectedCompanyId[uid] = id;

    const staffCode =
      this.selectedOneForEdit?.company?.[id]?.employments &&
      getEntries(
        (
          this.selectedOneForEdit.company as {
            [key: string]: ICompany;
          }
        )[id].employments
      ).sort((a, b) => {
        if (a[1].trud_dog.start_date === b[1].trud_dog.start_date) {
          return 0;
        } else if (a[1].trud_dog.start_date < b[1].trud_dog.start_date) {
          return 1;
        } else return -1;
      })[0][0];

    this.setSelectedTypeOfJob(
      uid,
      !withEmployment
        ? this.selectedTypeOfJob[uid]
          ? this.selectedTypeOfJob[uid]
          : staffCode
        : staffCode || ""
    );
  };

  setSelectedTypeOfJob = (uid: string, id: string) => {
    this.selectedTypeOfJob[uid] = id;
  };

  setAddedCompanies = (uid: string, company: string) => {
    if (!this.addedCompanies[uid]) {
      this.addedCompanies[uid] = getKeys(this.selectedOneForEdit.company);
    }
    let companiesNew = cloneDeep(this.addedCompanies[uid]);
    if (!this.addedCompanies[uid].includes(company)) {
      companiesNew.push(company);
      this.addedCompanies[uid] = companiesNew;
    } else if (this.addedCompanies[uid].includes(company)) {
      companiesNew = without(companiesNew, company);
      this.addedCompanies[uid] = companiesNew;
    }
  };

  clearAddedCompanies = (uid: string) => {
    this.addedCompanies[uid] && delete this.addedCompanies[uid];
  };

  setSelectedOneForEdit = (id: string) => {
    this.isLoading = true;
    this.error = false;

    if (isEmpty(this.rootStore.menuStore.allWindows)) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }

    if (!this.rootStore.menuStore.allWindows[`/staff/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
      delete this.openedAllStaffForEdit[id];
    }

    if (!isEmpty(this.openedAllStaffForEdit[id])) {
      this.selectedOneForEdit = this.openedAllStaffForEdit[id];

      this.setSelectedCompanyId(
        id,
        this.selectedCompanyId[id]
          ? this.selectedCompanyId[id]
          : getKeys(this.selectedOneForEdit["company"])[0]
      );

      this.setLastStatus(
        id,
        this.selectedCompanyId[id]
          ? this.selectedCompanyId[id]
          : getKeys(this.selectedOneForEdit["company"])[0]
      );
      this.isLoading = false;
    } else
      Promise.all([
        isEmpty(this.columns) && this.getStaffCardProps(),
        isEmpty(this.selects) && this.getSelects()
      ]).then(() => {
        this.getOneOfStaff(id);
      });
  };

  updateField = async (
    id: string,
    values:
      | Record<string, string | number | string[]>
      | Record<string, string>[]
      | Record<string, ICompany>
      | string[],
    type?: LoadingTypes
  ) => {
    if (type && this[`isLoading${startCase(type)}`] !== undefined) {
      this[`isLoading${startCase(type)}`] = true;
    } else this.isLoadingPhoto = true;
    this.success = false;
    const formData: {
      [key: string]: string | number;
    } = {};

    Object.entries(values).forEach(([key, value]) => {
      if (value !== null) {
        switch (key) {
          case "company":
            if (!(value as string[]).length) {
              formData["company"] = null;
            } else {
              Array.isArray(value) &&
                value.forEach((companyID, index) => {
                  formData[`company[${index}]`] = companyID;
                });
            }
            break;
          case "hasSnils":
          case "companies":
            return;
          default:
            if (!Array.isArray(value)) {
              formData[key] = value;
            } else {
              value.forEach((element, index) => {
                formData[`${key}[${index}]`] = element;
              });
            }
            break;
        }
      } else {
        formData[key] = null;
      }
    });

    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        currentClass: "staff",
        method: "update",
        body: { ...formData, id: id }
      });

      if (data["result"]) {
        await this.getOneOfStaff(id, type || undefined);
        this.rootStore.staffOneStore.setRebootStaff(id, true);
        this.isLoadingSettings = false;
        this.isLoadingPhoto = false;
      } else {
        await this.getOneOfStaff(id);
        runInAction(() => {
          this.isLoading = false;
          this.isLoadingPhoto = false;
          this.success = false;
          this.errorsMessage[id] = data;
          this.errorsMessage[id]["from"] = "company";
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
        this.isLoadingPhoto = false;
        this.success = false;
      });
    }
  };

  addOVData = async (
    rid: string,
    key: string,
    start_date: string,
    value: string,
    start_time?: string
  ) => {
    const formData = {
      rid,
      key,
      start_date,
      value: value ?? "",
      start_time: start_time ? `${start_time}:00` : "",
      company: this.selectedCompanyId[rid],
      staff_code: this.selectedTypeOfJob[rid] || 0
    };

    if (!formData["start_time"]) {
      delete formData["start_time"];
    }
    this.success = false;
    this.isLoadingForForm = true;
    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "addOVData",
        body: formData
      });

      if (data["result"]) {
        this.getOneOfStaff(rid);
        this.rootStore.staffOneStore.setRebootStaff(rid, true);
        if (this.objectVersionColumns[key]?.withHistory) {
          this.success = true;
        }
      } else {
        await this.getOneOfStaff(rid);
        runInAction(() => {
          this.errorsMessage[rid] = data;
          this.errorsMessage[rid]["from"] = "company";
          this.success = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  updateOVData = async (
    rid: string,
    id: string,
    value: string,
    key: string
  ) => {
    const formData = {
      id,
      value: value ?? ""
    };
    this.success = false;
    this.isLoadingForForm = true;
    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "updateOVData",
        body: formData
      });

      if (data["result"]) {
        this.getOneOfStaff(rid);
        this.rootStore.staffOneStore.setRebootStaff(rid, true);
        if (this.objectVersionColumns[key]?.withHistory) {
          this.success = true;
        }
      } else {
        runInAction(() => {
          this.error = true;
          this.success = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  deleteOVData = async (rid: string, id: string, key: string) => {
    const formData = { id };

    this.success = false;
    this.isLoadingForForm = true;
    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "deleteOVData",
        body: formData
      });

      if (data["result"]) {
        this.getOneOfStaff(rid);
        this.rootStore.staffOneStore.setRebootStaff(rid, true);
        if (this.objectVersionColumns[key]?.withHistory) {
          this.success = true;
        }
      } else {
        runInAction(() => {
          this.error = true;
          this.success = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  getDataURLtoFile = async (dataurl: string, filename: string) => {
    const array = dataurl.split(",");
    const mime = array[0].match(/:(.*?);/)[1];
    const response = await fetch(dataurl);
    const blob = await response.blob();

    return new File([blob], filename, { type: mime });
  };

  setUploadPhoto = async (id: string, file: File) => {
    this.success = false;
    this.isLoadingPhoto = true;
    try {
      const data: ApiResponse<{ hash: string; src: string }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "core",
          currentClass: "files",
          method: "uploadImage",
          params: { module: "core", hash: id },
          body: {
            file: file
          }
        });

      if (data["code"] === 200 && Object.values(data["result"]).length) {
        runInAction(() => {
          if (id) {
            this.getOneOfStaff(this.selectedOneForEdit["id"]);
            this.rootStore.staffOneStore.setRebootStaff(
              this.selectedOneForEdit["id"],
              true
            );
            this.success = true;
          } else {
            const newPhotoAlt: string[] = [];
            Object.values(this.selectedOneForEdit["photo_alt"]).map(
              (photo: { src: string | number; hash: string }) => {
                if (photo["src"] !== -1) {
                  newPhotoAlt.push(photo["hash"]);
                }
              }
            );
            newPhotoAlt.push(data["result"]["hash"]);

            this.updateField(
              this.selectedOneForEdit["id"],
              {
                photo_alt: newPhotoAlt
              },
              "photo"
            );
            this.success = true;
          }
        });
      } else {
        runInAction(() => {
          this.errorsMessage[id]["message"] = data["message"];
          this.errorsMessage[id]["from"] = "photo";
          this.success = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingPhoto = false;
      });
    }
  };

  makeAltPhotoTheMain = async (alt_photo_hash: string) => {
    this.isLoadingPhotoAsMain = true;

    try {
      const data: { code: number; hash: string } =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          method: "pickAltPhotoAsMain",
          body: {
            alt_photo_hash: alt_photo_hash
          }
        });

      if (data["code"] !== 404) {
        runInAction(() => {
          this.getOneOfStaff(this.selectedOneForEdit["id"]);
          this.rootStore.staffOneStore.setRebootStaff(
            this.selectedOneForEdit["id"],
            true
          );
        });
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingPhotoAsMain = false;
      });
    }
  };

  setDeletePhoto = async (hash: string) => {
    const formData = { module: "core", hash: hash };
    this.successDelete = false;
    try {
      const data: ApiResponse<number> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "core",
        currentClass: "files",
        method: "deleteAllFilesByHash",
        body: formData
      });
      if (data["result"]) {
        if (hash === this.selectedOneForEdit["id"]) {
          this.rootStore.staffOneStore.setRebootStaff(
            this.selectedOneForEdit["id"],
            true
          );
          this.getOneOfStaff(this.selectedOneForEdit["id"]);
          this.successDelete = true;
        } else {
          const newPhotoAlt: string[] = [];
          Object.values(this.selectedOneForEdit["photo_alt"]).map(
            (photo: { src: string | number; hash: string }) => {
              if (photo["src"] !== -1 && photo["hash"] !== hash) {
                newPhotoAlt.push(photo["hash"]);
              }
            }
          );

          this.updateField(this.selectedOneForEdit["id"], {
            photo_alt: newPhotoAlt.length ? newPhotoAlt : ""
          });
          this.successDelete = true;
        }
      } else {
        runInAction(() => {
          this.error = true;
          this.successDelete = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.successDelete = false;
      });
    }
  };

  getWidgetsForOneOfStaff = async (id: string) => {
    this.isLoading = true;

    try {
      const data: {
        widgets: {
          history: History;
          building_staff_events: {
            [key: string]: { [key: string]: { [key: string]: Status } };
          };
        };
        errors?: { [key: string]: string };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: ["history", "building_staff_events"]
        }
      });

      if (!data.errors) {
        this.historyData[id] = data.widgets.history;
        this.building_staff_events[id] = data.widgets.building_staff_events;
      } else {
        this.error = true;
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getStaffCardProps = async () => {
    try {
      const data: ApiResponse<StaffCardProps> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getStaffCardProps"
        });

      runInAction(() => {
        if (data.code == 200) {
          getValues(data.result.groups).forEach(
            (group) => (this.groups[group.id] = group)
          );
          // hardcode, добавляем группу Настроек для приложения
          this.groups.appsettings = {
            id: "appsettings",
            title: "Настройки для приложения",
            icon: "appsettings"
          };
          this.columns = data.result.columns;
          this.objectVersionColumns = data.result.ov_cols;
          this.tableParams = data.result.table_params;

          // в цикле ниже перебираем все колонки в table_params
          // смотрим, какие из них имеют справочники
          // и перезаписываем справочник, добавляя к каждому элементу значение newname
          getEntries(data.result.table_params).forEach(([name, col]) => {
            if (col.isvariable) {
              const param = {};

              getEntries(col.variable).forEach(([key, variable]) => {
                param[key] = {
                  ...variable,
                  newname: variable.id || variable.title
                };
              });

              this.tableParams[name].variable = param;
            }
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  getOneOfStaff = async (staff_id: string, type?: LoadingTypes) => {
    this.setErrorsMessage(staff_id, {});
    this.clearAddedCompanies(staff_id);
    try {
      const data: AllDataForEdit = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getOne",
        params: { staff_id }
      });

      if (!data.errors) {
        //следующие 4 строки удалить, когда поля инн, снилс и паспортных данных будут приходит корректно
        data.record.inn = data.record.inn.slice(0, -1);
        data.record.snils = data.record.snils.slice(0, -1);
        data.record.pasp_n = data.record.pasp_n.slice(0, -1);
        data.record.pasp_code = data.record.pasp_code.slice(0, -1);

        runInAction(() => {
          this.openedAllStaffForEdit[staff_id] = data.record;
          this.isTechAccess = data.is_tech_access;
          // Установка заблокированных колонок
          this.blockedCols[staff_id] = data.blocked_cols
            ? getValues(data.blocked_cols)
            : [];
          // Удаляем пустые и некорректные компании (типо -1, пустые строки и т.д.)
          if (data.record.company) {
            const companies: Record<string, ICompany> = {};
            getEntries(data.record.company as Record<string, ICompany>).forEach(
              ([key, company]) =>
                key && company?.id && (companies[company.id] = company)
            );

            this.openedAllStaffForEdit[staff_id].company = companies;
          }

          runInAction(() => {
            this.selectedOneForEdit =
              this.openedAllStaffForEdit[staff_id] || {};
          });

          Promise.all([isEmpty(this.selects) && this.getSelects()]).then(() => {
            // и после обработки данных обновляем вкладку
            this.rootStore.menuStore.updateTabWindow({
              mainPath: `/staff/id=${staff_id}`,
              title: `${data.record.surname || ""} ${data.record.name || ""}`
            });
          });
        });
      } else {
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${staff_id}`,
          title: "Ничего не найдено"
        });
        runInAction(() => {
          this.error = true;
          // hardcode - пока приходит 200 код при ошибке "Не найден сотрудник"
          this.errorText[staff_id] = data.message.head;
        });
      }

      if (this.selectedOneForEdit.company) {
        this.setSelectedCompanyId(
          staff_id,
          this.selectedCompanyId[staff_id] &&
            this.selectedCompanyId[staff_id] in this.selectedOneForEdit.company
            ? this.selectedCompanyId[staff_id]
            : Object.keys(this.selectedOneForEdit.company)[0]
        );
      }
      Promise.all([this.getWidgetsForOneOfStaff(staff_id)]).then(() => {
        runInAction(() => {
          if (!isEmpty(this.selectedOneForEdit.company)) {
            this.setLastStatus(
              staff_id,
              this.selectedCompanyId[staff_id]
                ? this.selectedCompanyId[staff_id]
                : getKeys(this.selectedOneForEdit.company)[0]
            );
          }

          if (type && this[`isLoading${startCase(type)}`] !== undefined) {
            this[`isLoading${startCase(type)}`] = false;
          }
          this.isLoading = false;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<Selects> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getSelects"
      });

      if (data.code == 200) {
        this.selects = data.result;
        getEntries(data.result).forEach(([key, value]) => {
          let directory = {};

          // для должностей остовляем справочник как есть
          if (key === "position") {
            directory = value;
          } else {
            // для остальных справочников
            // перезаписываем их, сохраняя только нужные нам поля для выпадающего списка newname и title
            getValues(
              value as Record<
                string,
                {
                  id: string;
                  title: string;
                }
              >
            ).forEach((item) => {
              directory[item?.id ? item.id : item.title] = {
                newname: item.id ? item.id : item.title,
                title: item.title
              };
            });
          }
          // и добавляем справочник в params, для унификации доступа к справочникам у всех полей
          !isEmpty(directory) &&
            (this.tableParams[key] = {
              ...this.tableParams[key],
              variable: directory
            });
        });

        // создаём справочник всех компаний сотрудника, хранящий только ключ id и title
        getValues(data.result.company).forEach((item) => {
          this.allCompaniesList[item.id] = {
            id: item.id,
            title: item.title
          };
          this.allCompanies[item.id] = item;
        });
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  resetPassword = async (id: string, number: string) => {
    this.errorsMessage = {};
    const bodyCredentials = {
      staff_id: id,
      phone: number
    };

    try {
      const data: ApiResponse<number> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        currentClass: "staff",
        method: "resetPassword",
        body: bodyCredentials
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if (data.errors) {
            this.errorsMessage[id].message.head = "Что-то пошло не так...";
          }
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  checkSnils = async (value: string) => {
    this.isLoadingField = "snils";

    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "checkSnils",
        params: {
          snils: value
        }
      });

      runInAction(() => {
        this.errorsMessage[this.rootStore.menuStore.tabId] = data;
        this.isLoadingField = "";
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingField = "";
      });
    }
  };

  setErrorsMessage = (id: string, value: Partial<IErrorStaffEdit>) => {
    this.errorsMessage[id] = value;
  };

  setHistoryError = (value: boolean) => {
    this.isHistoryError = value;
  };

  getRequiredCols = (
    cols: string[],
    data: Partial<IRecordsForEdit>
  ): string[] => {
    let requiredCols: string[] = [];

    cols.forEach((col) => {
      if (this.tableParams[col]?.required) {
        requiredCols.push(col);
      }
    });

    if (data.snils?.trim()?.length) {
      !requiredCols.includes("snils")
        ? (requiredCols = ["snils", ...requiredCols])
        : "";
    } else {
      requiredCols.includes("snils")
        ? (requiredCols = without(requiredCols, "snils"))
        : "";
    }

    return requiredCols;
  };

  setLastStatus = (id: string, company: string) => {
    this.lastStatus = "";
    if (
      this.building_staff_events[id]?.[company] &&
      Object.values(this.building_staff_events[id][company]).length &&
      Object.values(Object.values(this.building_staff_events[id][company])[0])
        .length
    ) {
      Object.values(
        Object.values(this.building_staff_events[id][company])[0]
      ).forEach((status) => {
        if (
          status["event_start"] &&
          parse(status["event_start"], "yyyy-MM-dd", new Date()) <= new Date()
        )
          return !this.lastStatus?.length
            ? (this.lastStatus = status["type"])
            : null;
      });
    }
  };

  editPhone = async (
    type: LoadingTypes,
    id: string,
    index: number,
    col: "comment" | "number",
    value: number | string,
    anotherColValue?: number | string
  ) => {
    if (this[`isLoading${startCase(type)}`] !== undefined) {
      this[`isLoading${startCase(type)}`] = true;
    }
    this.isLoadingPhone = true;
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "editPhone",
        body: { id, index, col, value: value ?? "" }
      });

      if (anotherColValue !== undefined) {
        this.editPhone(
          type,
          id,
          index,
          col === "comment" ? "number" : "comment",
          anotherColValue ?? ""
        );
      } else if (data.result) {
        if (anotherColValue === undefined) {
          this.getOneOfStaff(id, type);
          this.rootStore.staffOneStore.setRebootStaff(id, true);
        }
      } else {
        this.getOneOfStaff(id, type);
        runInAction(() => {
          this.errorsMessage[id] = data;
          this.errorsMessage[id]["from"] = "company";
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        if (this[`isLoading${startCase(type)}`] !== undefined) {
          this[`isLoading${startCase(type)}`] = false;
        }
      });
    }
  };

  deletePhone = async (id: string, index: number) => {
    this.isLoadingPhone = true;

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "deletePhone",
        body: { id, index }
      });

      if (data.result) {
        await this.getOneOfStaff(id, "phone");
        this.rootStore.staffOneStore.setRebootStaff(id, true);
      } else {
        await this.getOneOfStaff(id);
        runInAction(() => {
          this.isLoading = false;
          this.errorsMessage[id] = data;
          this.errorsMessage[id]["from"] = "company";
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingPhone = false;
      });
    }
  };

  addPhone = async (id: string, number: number | string, comment: string) => {
    this.isLoadingPhone = true;

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "addPhone",
        body: { id, number, comment }
      });

      if (data.result) {
        await this.getOneOfStaff(id, "phone");
        this.rootStore.staffOneStore.setRebootStaff(id, true);
      } else {
        await this.getOneOfStaff(id, "phone");
        runInAction(() => {
          this.errorsMessage[id] = data;
          this.errorsMessage[id]["from"] = "company";
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingPhone = false;
      });
    }
  };

  getValuesForEdit = (group: string) => {
    const id = this.rootStore.menuStore.tabId;
    const editFields: Partial<IRecordsForEdit> = {};
    getValues(this.groups[group]["columns"]).forEach((col) => {
      // для булевых полей может прийти null, в object записываем number для Switch-поля
      if (this.columns?.[col]?.type === "bool") {
        return (editFields[col] = this.selectedOneForEdit[col] ? 1 : 0);
      }
      // Для Object Version полей (данные трудоустройства)
      // Данные нужно брать из выбранной компании
      if (getKeys(this.objectVersionColumns).includes(col)) {
        // Данные выбранной компании
        const companyOne: ICompany =
          this.selectedOneForEdit?.company?.[this.selectedCompanyId?.[id]];
        // Данные выбранного трудоустройства в выбранной компании
        const employment =
          companyOne?.employments?.[this.selectedTypeOfJob[id]];
        // Значение поля, либо пустая строка
        const value =
          "value" in (employment?.[col] || {})
            ? employment?.[col]?.value
            : employment?.[col] || "";

        // Записываем значение в editFields
        editFields[col] = value;
        return;
      }

      switch (col) {
        case "company":
          // массив компаний для SelectMulti
          editFields[col] = getKeys(this.selectedOneForEdit[col]);
          // данные по компаниям записываем в companies
          editFields["companies"] = this.selectedOneForEdit[col];
          break;
        default:
          editFields[col] = this.selectedOneForEdit[col]
            ? this.selectedOneForEdit[col]
            : null;
      }
    });
    return editFields;
  };
  setOpenedOVModal = (key: string, id: string) =>
    (this.openedOVModal[id] = key);

  resetAll = () => {
    this.error = false;
    this.errorText = {};
    this.errorsMessage = {};
    this.isLoading = false;
    this.isLoadingPhoto = false;
    this.isLoadingForForm = false;
    this.isLoadingSettings = false;
    this.isLoadingPhone = false;
    this.isLoadingEmail = false;
    this.isLoadingField = "";
    this.success = false;
    this.isTechAccess = false;

    this.historyData = {};
    this.building_staff_events = {};

    this.columns = {};
    this.tableParams = {};
    this.groups = {};
    this.selects = {};
    this.addedCompanies = {};

    this.selectedOneForEdit = {};
    this.openedAllStaffForEdit = {};
    this.openedOVModal = {};

    this.allCompanies = {};

    this.selectedCompanyId = {};
    this.lastStatus = "";
    this.selectedTypeOfJob = {};
    this.objectVersionColumns = {};
  };
}
