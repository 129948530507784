import styles from "./operationFormFields.module.scss";
import Select from "shared/ui/Inputs/Select";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import NumberFormat from "react-number-format";
import { Input } from "shared/ui/Inputs/Input";
import Textarea from "shared/ui/Inputs/Textarea";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import Alert from "shared/ui/Alert";
import { Errors } from "stores/utils/types/ErrorsType";

type OperationFormFieldsProps = {
  requiredCols: string[];
  disabledFields: string[];
  isHoldingEdu: boolean;
  successMessage: Errors["message"];
  ws_num: number;
  money: number;
  fields: string[];
};

const OperationFormFields = ({
  requiredCols,
  disabledFields,
  isHoldingEdu,
  successMessage,
  ws_num,
  money,
  fields
}: OperationFormFieldsProps) => {
  const { operationFormStore, staffOneSalaryStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const { values, setFieldValue, handleChange, handleBlur } =
    useFormikContext<Record<string, string | number>>();

  const { id } = useParams();

  useEffect(() => {
    if (
      operationFormStore.pageStaff !== 1 &&
      operationFormStore.pageStaff <= operationFormStore.maxPageStaff &&
      operationFormStore.pageStaff !== operationFormStore.prevPageStaff
    ) {
      operationFormStore.getMoreStaff();
    }
  }, [operationFormStore.pageStaff, operationFormStore.maxPageStaff]);

  const changeOpenedListName = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };
  const handleGetBuildingForStaff = (date: string) => {
    if ("building" in values) {
      staffOneSalaryStore.getBuildingForStaff(id, date);
      setFieldValue("building", null);
    }
  };

  return (
    <div className={styles.inputs}>
      {fields.map((field) => {
        const disabled = disabledFields && disabledFields.includes(field);
        switch (field) {
          case "time_create":
          case "time_spending":
          case "date_start":
          case "date":
            return (
              <DatePickerField
                key={field}
                name={field}
                title={staffOneSalaryStore.salaryCols[field]?.["title"]}
                isCalendarOpened={openedListName === field}
                setIsCalendarOpened={() => {
                  changeOpenedListName(field);
                }}
                disabled={disabled}
                required={requiredCols.includes(field)}
                onChange={handleGetBuildingForStaff}
              />
            );

          case "object":
            return (
              <Select
                key={field}
                name={field}
                isFloating
                title={staffOneSalaryStore.salaryCols[field]["title"]}
                options={staffOneSalaryStore.buildingForStaff}
                valueName="id"
                required={requiredCols.includes(field)}
                disabled={disabled}
              />
            );

          case "type":
          case "property":
            return (
              <Select
                key={field}
                name={field}
                isFloating
                title={staffOneSalaryStore.salaryCols[field]?.["title"]}
                options={
                  field === "type"
                    ? staffOneSalaryStore.availableOperationTypes[id]
                    : staffOneSalaryStore.operationProperty[values.type ?? ""]
                }
                valueName="id"
                required={requiredCols.includes(field)}
                disabled={disabled}
              />
            );

          case "ws_num":
            return (
              <NumberFormat
                key={field}
                name={field}
                value={values[field]}
                label={staffOneSalaryStore.salaryCols[field]?.["title"]}
                onChange={handleChange}
                onBlur={handleBlur}
                customInput={Input}
                disabled={disabled}
                allowNegative={false}
                required={requiredCols.includes(field)}
              />
            );
          case "money":
            return (
              <div className={styles.sumField} key={field}>
                <NumberFormat
                  name={field}
                  value={values[field]}
                  label={staffOneSalaryStore.salaryCols[field]?.["title"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  customInput={Input}
                  disabled={disabled}
                  thousandSeparator=" "
                  decimalSeparator=","
                  allowedDecimalSeparators={["."]}
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  required={requiredCols.includes(field)}
                />
                {isHoldingEdu ? (
                  <p>{`Стоимость одной смены: ${money / ws_num}`}</p>
                ) : null}
              </div>
            );

          case "comment":
            return (
              <Textarea
                key={field}
                name={field}
                value={(values[field] as string) || ""}
                label={staffOneSalaryStore.salaryCols[field]?.["title"]}
                disabled={disabled}
                onChange={(event) => {
                  handleChange(event);
                }}
                placeholder={staffOneSalaryStore.salaryCols[field]?.["title"]}
                commentCol={staffOneSalaryStore.salaryCols[field]}
              />
            );

          case "initiator":
          case "uid":
            return (
              <Select
                key={field}
                name={field}
                isFloating
                title={staffOneSalaryStore.salaryCols[field]?.["title"]}
                options={operationFormStore.staffList}
                isSearchable
                isSearchWithPagination
                page={operationFormStore.pageStaff}
                prevPage={operationFormStore.prevPageStaff}
                maxPage={operationFormStore.maxPageStaff}
                setPage={operationFormStore.setPageStaff}
                getList={operationFormStore.getStaffList}
                setSearchValue={operationFormStore.setSearchValueStaff}
                searchValue={operationFormStore.searchValueStaff}
                isLoading={operationFormStore.isLoadingForStaffList}
                valueName="id"
                disabled={disabled}
                required={requiredCols.includes(field)}
              />
            );
          case "status":
            return;
          default:
            return (
              <Input
                key={field}
                name={field}
                label={staffOneSalaryStore.salaryCols[field]?.["title"]}
                onChange={(event) => {
                  handleChange(event);
                }}
                required={requiredCols.includes(field)}
                disabled={disabled}
              />
            );
        }
      })}
      {successMessage ? <Alert errors={successMessage} /> : null}
    </div>
  );
};

export default observer(OperationFormFields);
