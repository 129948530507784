import styles from "./friendInviteStaffForm.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Link } from "react-router-dom";
import { Badge } from "shared/ui/Badge";
import { getValues } from "shared/utils/helpers/getValues";
import { fileUrl } from "stores/utils/consts";
import { useFormikContext } from "formik";

type FriendInviteStaffFormProps = {
  id: string;
  title: string;
};

const FriendInviteStaffForm = ({ id, title }: FriendInviteStaffFormProps) => {
  const { friendInviteStore } = useStores();
  const { values } = useFormikContext<{ value: string }>();

  return (
    <div className={styles.staff} data-type="list">
      <div data-type="list">
        {values.value && (
          <Link
            to={`/staff/id=${values.value}`}
            id={`FriendInviteStaffForm_${title}_${values.value}`}
            className={styles.link}
            data-type="list"
            target="_blank"
          >{`ТН ${friendInviteStore.invitesList[id]?.[title]?.tn || ""}${
            " " + friendInviteStore.invitesList[id]?.[title]?.name || ""
          }`}</Link>
        )}
      </div>

      {values.value &&
        getValues(friendInviteStore.invitesList[id]?.[title].events).map(
          (event, idx) => {
            return (
              <div
                className={styles.event}
                key={`event_${id}_${idx}`}
                data-type="list"
              >
                <div className={styles.company} data-type="list">
                  <Badge
                    text={
                      friendInviteStore.companiesList?.[event.company]?.title ||
                      event.company
                    }
                    colorName={
                      friendInviteStore.companiesList?.[event.company]?.color ||
                      "primary"
                    }
                  />
                  <Badge
                    text={event.type.title}
                    textColorName="bw-gray7"
                    icon={event.type.icon}
                    iconColor={event.type.color}
                    colorName="none"
                  />
                </div>
                <a
                  href={`${fileUrl}/crm/building/?id=${event.building?.id}`}
                  id={`FriendInviteStaffForm_${title}_${event.building?.id}`}
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                  data-type="list"
                >
                  {event.building?.title}
                </a>
              </div>
            );
          }
        )}
    </div>
  );
};

export default observer(FriendInviteStaffForm);
