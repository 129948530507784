import styles from "./staffOneEditFormCompanies.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { ICompany } from "stores/StaffModule/types/RecordsForEdit";
import { useParams } from "react-router-dom";
import TruddogTabs from "./TruddogTabs";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import SelectMulti from "shared/ui/Inputs/SelectMulti";
import { getKeys } from "shared/utils/helpers/getKeys";
import { useEffect } from "react";
import { useFormikContext } from "formik";
import { diff } from "deep-object-diff";

type StaffOneEditFormCompaniesProps = {
  values: { companies: Record<string, ICompany>; company: string[] };
  required: boolean;
};

const StaffOneEditFormCompanies = ({
  values,
  required
}: StaffOneEditFormCompaniesProps) => {
  const { staffOneEditStore } = useStores();
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { id } = useParams();

  const companiesTabs = values.company?.map((company_id) => {
    return {
      id: company_id,
      title: staffOneEditStore.allCompanies[company_id]?.title
    };
  });

  const selectedCompany = staffOneEditStore.selectedCompanyId[id];

  useEffect(() => {
    if (diff(values.company, staffOneEditStore.addedCompanies[id])) {
      setTimeout(() => {
        setFieldValue("company", staffOneEditStore.addedCompanies[id]);
      }, 10);

      setTimeout(() => {
        setFieldTouched("company");
      }, 100);
    }
  }, [
    id,
    staffOneEditStore.selectedCompanyId[id],
    staffOneEditStore.selectedTypeOfJob[id]
  ]);

  return (
    <>
      <SelectMulti
        name="company"
        label={staffOneEditStore.columns?.company?.title}
        required={required}
        options={staffOneEditStore.allCompaniesList}
        valueName="id"
        onClick={(option) =>
          staffOneEditStore.setAddedCompanies(id, option.id as string)
        }
        handleDeleteItem={(company) =>
          staffOneEditStore.setAddedCompanies(id, company)
        }
      />

      <ButtonsTabs
        tabs={companiesTabs}
        selectedTab={selectedCompany}
        changeActiveTab={(company_id) =>
          staffOneEditStore.setSelectedCompanyId(id, company_id, true)
        }
        className={{ container: styles.tabsContainer }}
        disabledFunc={(company: string) =>
          !getKeys(
            staffOneEditStore.selectedOneForEdit?.company as Record<
              string,
              ICompany
            >
          ).includes(company)
        }
        tooltipFunc={(company: string) =>
          !getKeys(
            staffOneEditStore.selectedOneForEdit?.company as Record<
              string,
              ICompany
            >
          ).includes(company)
            ? "Редактирование данных по компании будет доступно после сохранения изменений"
            : undefined
        }
      />
      <TruddogTabs values={values} />
    </>
  );
};

export default observer(StaffOneEditFormCompanies);
