import { makeAutoObservable, runInAction } from "mobx";
import { InvitesListItem } from "../types/InvitesListItem";
import RootStore from "stores";
import { Company } from "stores/StaffModule/types/Company";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { isEmpty } from "lodash";
import { FriendInviteSelects } from "../types/FriendInviteSelects";
import { Param } from "stores/utils/types/Param";
import { Col } from "stores/utils/types/Col";
import { getValues } from "shared/utils/helpers/getValues";

export type FieldsGroups = Readonly<
  ["status", "time_create", "candidate", "uc", "pay"]
>;
export const groups: Readonly<Record<FieldsGroups[number], string[]>> = {
  status: ["status", "pay"],
  time_create: ["time_create", "author"],
  candidate: ["candidate_surname", "candidate_name", "candidate_birthday"],
  uc: ["uc", "uc_date"],
  pay: ["pay_sum", "pay_date"]
};

export default class FriendInviteOneStore {
  error = false;
  isLoading = false;
  errorMessage = "";

  //Статичные данные и настройки
  cols: Record<string, Col> = {};
  tableParams: Record<string, Param> = {};
  selects: Partial<FriendInviteSelects> = {};
  companies: { [company_id: string]: Company } = {};

  // состояние выпадающих списков для таблицы
  openedList = "";

  selectedOne: Partial<InvitesListItem> = {};
  openedAllInvites: Record<string, Partial<InvitesListItem>> = {};

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  setSelectedOneInvite = (id: string) => {
    this.isLoading = true;
    this.error = false;

    if (isEmpty(this.rootStore.menuStore.allWindows)) {
      this.rootStore.menuStore.addWindow("/friendinvite", "Приведи друга");
    }

    if (!this.rootStore.menuStore.allWindows[`/friendinvite/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/friendinvite/id=${id}`,
        "Загрузка..."
      );
      delete this.openedAllInvites[id];
    }

    if (!isEmpty(this.openedAllInvites[id])) {
      if (!isEmpty(this.openedAllInvites[id])) {
        this.selectedOne = this.openedAllInvites[id];
      } else {
        runInAction(() => (this.error = true));
      }
      runInAction(() => (this.isLoading = false));
    } else {
      Promise.all([
        isEmpty(this.companies) && this.getCompanies(),
        isEmpty(this.cols) && this.getCols(),
        isEmpty(this.tableParams) && this.getTableParams(),
        isEmpty(this.selects) && this.getSelects()
      ]).then(() => this.getOneInviteFriendData(id));
    }
  };

  getOneInviteFriendData = async (id: string) => {
    this.isLoading = true;
    this.error = false;

    try {
      const data: ApiResponse<InvitesListItem> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getOne",
          params: { id }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.selectedOne = data.result;

          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/friendinvite/id=${id}`,
            title: `${this.selectedOne["candidate_surname"] || ""} ${
              this.selectedOne["candidate_name"] || ""
            }`
          });

          this.openedAllInvites[id] = data.result;
        } else {
          this.openedAllInvites[id] = {};
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/friendinvite/id=${id}`,
            title: "Ничего не найдено"
          });
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getCompanies = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Company } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "company",
          currentClass: "company",
          method: "getList"
        });

      runInAction(() => {
        if (data.result !== -1) {
          getValues(data.result).forEach((company) => {
            this.companies[company.id] = company;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<FriendInviteSelects> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getSelects"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.selects = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCols = async () => {
    try {
      const data: ApiResponse<Record<string, Col>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getCols"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.cols = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<Record<string, Param>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.tableParams = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };
}
