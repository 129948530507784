import { makeAutoObservable, runInAction } from "mobx";
import { HistoryInvite, InvitesListItem } from "./types/InvitesListItem";
import { Col } from "./types/Col";
import { InviteFriendData } from "./types/InviteAllFriendData";
import RootStore from "stores";
import { Company } from "stores/StaffModule/types/Company";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";
import { Param } from "stores/utils/types/Param";
import { FriendInviteSelects } from "./types/FriendInviteSelects";

export default class FriendInviteStore {
  error = false;
  isLoading = false;
  errorMessage = "";

  invitesList: Record<string, InvitesListItem> = {};

  ordered = "time_create";
  order = "DESC";

  onPage = 100;
  page = 1;
  maxPage = 0;
  prevPage = 1;

  searchValue = "";

  //Статичные данные и настройки
  cols: Record<string, Col> = {};
  tableParams: Record<string, Param> = {};
  selects: Partial<FriendInviteSelects> = {};

  inviteOneFriendItem: Partial<InvitesListItem> = {};
  inviteOneFriendTitles: { [key: string]: Col } = {};
  inviteAllFriendData: Partial<InviteFriendData> = {};
  colsTitles: { [key: string]: Col } = {};
  history: { [key: string]: HistoryInvite } = {};
  companiesList: { [company_id: string]: Company } = {};

  showFields: string[] = [];

  selectedOne = {};
  openedAllInvites = {};

  actionButton = {
    0: {
      id: 1,
      title: "OK"
    }
  };

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  resetAll = () => {
    this.ordered = "time_create";
    this.order = "DESC";
    this.onPage = 100;
    this.page = 1;
    this.maxPage = 0;
    this.prevPage = 1;
    this.searchValue = "";
    this.showFields = [];
    this.colsTitles = {};
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setOrder = (ordered: string) => {
    this.ordered = ordered;
    this.order = this.order === "ASC" ? "DESC" : "ASC";
  };

  changeWidgets = (obj?: Partial<InvitesListItem>) => {
    if (obj) {
      this.history = obj["history"];
    } else {
      this.history = {};
    }
  };

  setSelectedOneInvite = (id: string) => {
    this.isLoading = true;
    this.error = false;

    this.changeWidgets();
    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/friendinvite", "Приведи друга");
    }

    if (!this.rootStore.menuStore.allWindows[`/friendinvite/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/friendinvite/id=${id}`,
        "Загрузка..."
      );
      delete this.openedAllInvites[id];
    }

    if (
      Object.keys(this.openedAllInvites).length &&
      this.openedAllInvites[id]
    ) {
      if (Object.values(this.openedAllInvites[id]).length) {
        this.inviteOneFriendItem = this.openedAllInvites[id];
        this.changeWidgets(this.openedAllInvites[id]);
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
      runInAction(() => {
        this.isLoading = false;
      });
    } else {
      Promise.all([
        !Object.values(this.companiesList).length && this.getCompanies(),
        this.getOneInviteFriendData(id)
      ]).then(() => {
        runInAction(() => {
          this.isLoading = false;
          this.error = false;
        });
      });
    }
  };

  getPage = async () => {
    runInAction(() => (this.isLoading = true));
    this.errorMessage = "";
    this.page = 1;
    this.rootStore.menuStore.setIsScrollBottom(true);
    this.invitesList = {};

    const params: { [key: string]: string } =
      this.ordered === "uid"
        ? {
            order_by: "name"
          }
        : { order: this.ordered };

    params.ordered = this.order;

    if (this.searchValue) {
      params["fast_search"] = this.searchValue;
    }

    try {
      const data: InviteFriendData = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "friendinvite",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: { ...params }
      });
      runInAction(() => {
        if (!isEmpty(data.records)) {
          getValues(data.records).forEach((invite) => {
            !this.invitesList[invite.id] &&
              (this.invitesList[invite.id] = invite);
          });

          this.prevPage = this.page;
          this.maxPage = data.nav.max_page;
        } else {
          this.invitesList = {};
          this.errorMessage = "Ничего не найдено";
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoading = false;
      });
    }
  };

  getMorePage = async () => {
    this.errorMessage = "";
    this.isLoading = true;


    const params: { [key: string]: string } =
      this.ordered === "uid"
        ? {
            order_by: "name"
          }
        : { order: this.ordered };

    params.ordered = this.order;

    if (this.searchValue) {
      params["fast_search"] = this.searchValue;
    }

    try {
      const data: InviteFriendData = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "friendinvite",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: { ...params }
      });
      runInAction(() => {
        if (!isEmpty(data.records)) {
          getValues(data.records).forEach((invite) => {
            !this.invitesList[invite.id] &&
              (this.invitesList[invite.id] = invite);
          });

          this.rootStore.menuStore.isScrollBottom = false;
          this.prevPage = this.page;
          this.maxPage = data.nav.max_page;
        } else {
          this.invitesList = {};
          this.errorMessage = "Ничего не найдено";
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoading = false;
      });
    }
  };

  getAllInviteFriendData = async () => {
    this.invitesList = {};
    this.setPage(1);
    Promise.all([
      isEmpty(this.companiesList) && this.getCompanies(),
      isEmpty(this.cols) && this.getCols(),
      isEmpty(this.selects) && this.getSelects(),
      isEmpty(this.tableParams) && this.getTableParams(),
      this.getPage()
    ]).then(() => {
      runInAction(() => {
        this.isLoading = false;
        this.error = false;
      });
    });
  };

  getPageWithFilter = () => {
    this.getPage();
  };

  getOneInviteFriendData = async (id: string) => {
    this.isLoading = true;
    this.error = false;

    try {
      const data: InviteFriendData & { record: InvitesListItem } =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getOne",
          params: {
            id: id
          }
        });
      runInAction(() => {
        if (Object.values(data["record"]).length) {
          this.inviteOneFriendItem = data["record"];
          this.inviteOneFriendTitles = data["cols"];
          this.history = data["record"]["history"];
          this.showFields = Object.values(data["show_fields"]);

          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/friendinvite/id=${id}`,
            title: `${
              this.inviteOneFriendItem["candidate_surname"]
                ? this.inviteOneFriendItem["candidate_surname"]
                : ""
            } ${
              this.inviteOneFriendItem["candidate_name"]
                ? this.inviteOneFriendItem["candidate_name"]
                : ""
            }`
          });

          this.openedAllInvites[id] = data["record"];
          this.rootStore.menuStore.tabId === id &&
            this.changeWidgets(this.inviteOneFriendItem);
        } else {
          this.openedAllInvites[id] = {};
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/friendinvite/id=${id}`,
            title: "Ничего не найдено"
          });
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getCompanies = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Company } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "company",
          currentClass: "company",
          method: "getList"
        });

      runInAction(() => {
        if (data.result !== -1) {
          getValues(data.result).forEach((company) => {
            this.companiesList[company.id] = company;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<FriendInviteSelects> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getSelects"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.selects = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCols = async () => {
    try {
      const data: ApiResponse<Record<string, Col>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getCols"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.cols = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<Record<string, Param>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.tableParams = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };
}
