import styles from "./friendInviteStaff.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useRef } from "react";
import { Formik } from "formik";
import FriendInviteStaffForm from "./FriendInviteStaffForm";

type FriendInviteStaffProps = {
  id: string;
  title: string;
};

const FriendInviteStaff = ({ id, title }: FriendInviteStaffProps) => {
  const { friendInviteStore } = useStores();
  const referenseRef = useRef<HTMLTableDataCellElement>();

  return friendInviteStore.invitesList[id]?.[title] !== null ? (
    <td className={styles.td} data-type="list" ref={referenseRef}>
      <Formik
        initialValues={{
          value: friendInviteStore.invitesList[id][title]?.id || null
        }}
        onSubmit={() => null}
      >
        {() => (
          <>
            <FriendInviteStaffForm id={id} title={title} />
          </>
        )}
      </Formik>
    </td>
  ) : (
    <td className={styles.td}></td>
  );
};

export default observer(FriendInviteStaff);
