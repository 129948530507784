import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import FriendInviteAllTable from "./FrienInviteAllTable/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import TableWithStickyFilter from "features/TableWithStickyFilter";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { browserName } from "react-device-detect";
import { isEmpty } from "lodash";

const FriendInviteAll = () => {
  const { menuStore, friendInviteStore, filterStore } = useStores();

  useEffect(() => {
    if (isEmpty(friendInviteStore.invitesList)) {
      friendInviteStore.getAllInviteFriendData();
    }
    menuStore.setSavedScroll();
    menuStore.setOpenedModule("friendInvite");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/friendinvite", "Приведи друга");
  }, []);

  useEffect(() => {
    if (
      friendInviteStore.page !== 1 &&
      friendInviteStore.page <= friendInviteStore.maxPage &&
      friendInviteStore.page !== friendInviteStore.prevPage
    ) {
      friendInviteStore.getMorePage();
    }
  }, [friendInviteStore.page, friendInviteStore.maxPage]);

  return (
    <>
      <TableWithStickyFilter
        isLoading={friendInviteStore.isLoading}
        isLoadingForFilters={false}
        filters={undefined}
        searchValue={friendInviteStore.searchValue}
        setSearchValue={friendInviteStore.setSearchValue}
        getData={friendInviteStore.getPage}
        cols={{}}
        params={{}}
        allCols={{}}
        currentCols={[]}
        getDataWithFilter={friendInviteStore.getPageWithFilter}
        isSearchable
        withoutAdvancedSearch
        withoutSavedFilter
        withoutColsSetting
        customConditionDisplayForInfoBlock={
          !friendInviteStore.searchValue.length
        }
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight ?? -2,
          Boolean(friendInviteStore.searchValue),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        searchInputTooltip="В этом поле можно искать несколько рекомендатей, если вбить ТН номера через пробел"
        fixedWidthSearchInput
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <FriendInviteAllTable />
        </ErrorBoundary>
      </TableWithStickyFilter>
    </>
  );
};

export default observer(FriendInviteAll);
