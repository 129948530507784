import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { format } from "date-fns";
import validation from "./validation";
import FormWindow from "shared/ui/FormWindow";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { Errors } from "stores/utils/types/ErrorsType";
import StaffSalaryFormFields from "./OperationFormFields";
import { isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";

type OperationFormProps = {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  clearErrorsMessage?: () => void;
  saveBtnOnClickFunc?: (
    values: {
      [key: string]: string | number;
    },
    uid?: string
  ) => void;
  fields: string[];
  disabledFields?: string[];
  dataForEdit?: { [key: string]: string | number };
  title: string;
  nameOneOfStaff?: string;
  type: "add" | "edit" | "";
  isHoldingEdu?: boolean;
  staffID?: string;
  errorsMessage?: Errors["message"];
  successMessage?: Errors["message"];
  ws_num?: number;
  money?: number;
  saveSuccessModal?: boolean;
  onSuccessModalClose?: () => void;
  isLoadingForModal?: boolean;
  requiredFields?: string[];
};

const OperationForm = ({
  isOpenModal,
  setIsOpenModal,
  clearErrorsMessage,
  fields,
  disabledFields,
  title,
  nameOneOfStaff,
  type,
  dataForEdit,
  isHoldingEdu,
  saveBtnOnClickFunc,
  staffID,
  errorsMessage,
  successMessage,
  ws_num,
  money,
  saveSuccessModal,
  onSuccessModalClose,
  isLoadingForModal,
  requiredFields = []
}: OperationFormProps) => {
  const { operationFormStore } = useStores();

  const { id } = useParams();
  const isAdd = type === "add" && fields.length;
  const isEdit = type === "edit" && !isEmpty(dataForEdit) && fields.length;
  const getInitialValuesForAdd = () => {
    const values: { [key: string]: string | number } = {};
    fields.forEach((key) => {
      if (key.includes("comment")) return (values[key] = "");
      switch (key) {
        case "time_create":
        case "time_spending":
        case "date_start":
        case "date":
          values[key] = format(new Date(), "yyyy-MM-dd");
          break;
        case "ws_num":
          values[key] = isHoldingEdu ? ws_num : null;
          break;
        case "money":
          values[key] = isHoldingEdu ? money : null;
          break;
        default:
          values[key] = null;
      }
    });
    return values;
  };
  const getInitialValuesForEdit = () => {
    const values: { [key: string]: string | number } = {};
    fields.forEach((key) => {
      switch (key) {
        case "time_create":
        case "time_spending":
        case "date_start":
          values[key] = (dataForEdit[key] as string)
            .split(".")
            .reverse()
            .join("-");
          break;
        default:
          values[key] = dataForEdit[key];
      }
    });
    return values;
  };

  useEffect(() => {
    if (isOpenModal) {
      clearErrorsMessage();
      isEmpty(operationFormStore.statusList) &&
        operationFormStore.getStatusList();
      operationFormStore.setSearchValueStaff("");
      operationFormStore.getStaffList();
    }
  }, [isOpenModal]);

  if (isOpenModal && (isAdd || isEdit)) {
    return (
      <Formik
        initialValues={
          type === "add" ? getInitialValuesForAdd() : getInitialValuesForEdit()
        }
        enableReinitialize
        onSubmit={() => null}
        validateOnMount
        validateOnBlur
        validateOnChange
        validationSchema={validation(fields, requiredFields)}
      >
        {({ values, isValid, dirty }) => {
          return (
            <>
              <FormWindow
                title={title}
                subtitle={nameOneOfStaff}
                setOpenWindow={() => {
                  setIsOpenModal();
                  clearErrorsMessage();
                }}
                saveBtnTitle={type === "add" ? "Добавить" : "Сохранить"}
                saveBtnOnClickFunc={() => {
                  saveBtnOnClickFunc(
                    values,
                    type === "add"
                      ? id ?? staffID
                      : (dataForEdit["id"] as string)
                  );
                }}
                saveBtnDisabledValue={
                  !isValid || (type === "edit" ? !dirty : false)
                }
                successMessageTitle={{
                  save: successMessage
                    ? successMessage?.head
                    : type === "add"
                    ? "Успешно добавлено"
                    : "Успешно Сохранено"
                }}
                saveSuccessModal={saveSuccessModal}
                onSuccessModalClose={onSuccessModalClose}
                addBtnTitle="Отмена"
                addBtnImg={<IconClose />}
                errors={
                  errorsMessage &&
                  getValues(errorsMessage).length && [errorsMessage]
                }
                isLoadingForModal={isLoadingForModal}
                isScroll
              >
                <StaffSalaryFormFields
                  fields={fields}
                  requiredCols={requiredFields}
                  disabledFields={disabledFields}
                  successMessage={successMessage}
                  isHoldingEdu={isHoldingEdu}
                  ws_num={ws_num}
                  money={money}
                />
              </FormWindow>
            </>
          );
        }}
      </Formik>
    );
  }
};

export default observer(OperationForm);
